import React from "react"
// import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Hero, Cards } from "../components/template"
import Seo from "../components/seo"
export default function Page({ pageContext: { data } }) {
  return (
    // <code>{JSON.stringify(data)}</code>
    <Layout>
      <Seo
        title={data.title || data.name}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      <Hero property={data} className="bg-black bg-opacity-50" />
      <Cards className="pt-12" link="/" list={data.listings} />
    </Layout>
  )
}
